/** @format */

import "./styles.css";
import facebook from "../../assets/images/facebook.png";
import twitter from "../../assets/images/twitter.png";
import instagram from "../../assets/images/instagram.png";
import linkedin from "../../assets/images/linkedin.png";

const Footer = (props) => {
  return (
    <>
      <div className="mainfooter" id="footer_id">
        <div className="mainfooter-ornaments_1">
          <div className="mainfooter-retangle_1"></div>
          <div className="mainfooter-retangle_2"></div>
        </div>
        <div className="mainfooter-description">
          <svg
            width="172"
            height="32"
            viewBox="0 0 172 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M58.4498 10.0694H50.6978V5.98432H70.7029V10.0694H62.9509V31.355H58.4498V10.0694Z"
              fill="white"
            />
            <path
              d="M72.1279 24.2598C72.2708 25.5498 72.7709 26.5532 73.6283 27.2699C74.4857 27.9866 75.5216 28.3449 76.7362 28.3449C77.8079 28.3449 78.701 28.1299 79.4155 27.6999C80.1538 27.246 80.7968 26.6846 81.3445 26.0157L84.4167 28.3449C83.4165 29.5872 82.2971 30.4711 81.0587 30.9966C79.8203 31.5222 78.5224 31.785 77.1649 31.785C75.8789 31.785 74.6643 31.57 73.5211 31.14C72.378 30.71 71.3896 30.1008 70.5561 29.3124C69.7225 28.5241 69.0557 27.5804 68.5556 26.4815C68.0793 25.3587 67.8411 24.1165 67.8411 22.7548C67.8411 21.3931 68.0793 20.1627 68.5556 19.0638C69.0557 17.941 69.7225 16.9854 70.5561 16.1971C71.3896 15.4087 72.378 14.7996 73.5211 14.3695C74.6643 13.9395 75.8789 13.7245 77.1649 13.7245C78.3557 13.7245 79.4393 13.9395 80.4157 14.3695C81.416 14.7757 82.2614 15.3729 82.9521 16.1613C83.6665 16.9496 84.2143 17.9291 84.5954 19.0997C85.0002 20.2464 85.2027 21.5722 85.2027 23.0773V24.2598H72.1279ZM80.9159 21.0347C80.892 19.7686 80.4991 18.7772 79.737 18.0605C78.9749 17.3199 77.9151 16.9496 76.5576 16.9496C75.2716 16.9496 74.2475 17.3199 73.4854 18.0605C72.7471 18.801 72.2946 19.7925 72.1279 21.0347H80.9159Z"
              fill="white"
            />
            <path
              d="M100.214 19.3147C99.7141 18.7891 99.1902 18.371 98.6424 18.0605C98.0947 17.7499 97.535 17.5946 96.9634 17.5946C96.1775 17.5946 95.475 17.738 94.8558 18.0246C94.2366 18.3113 93.7007 18.6935 93.2482 19.1713C92.8195 19.6491 92.4861 20.1986 92.248 20.8197C92.0336 21.4408 91.9265 22.0859 91.9265 22.7548C91.9265 23.4237 92.0336 24.0687 92.248 24.6898C92.4861 25.3109 92.8195 25.8604 93.2482 26.3382C93.7007 26.816 94.2366 27.1982 94.8558 27.4849C95.475 27.7716 96.1775 27.9149 96.9634 27.9149C97.6303 27.9149 98.2495 27.7835 98.8211 27.5207C99.3926 27.234 99.8809 26.8279 100.286 26.3024L103.144 29.3841C102.715 29.8141 102.215 30.1844 101.643 30.495C101.095 30.8055 100.536 31.0564 99.9642 31.2475C99.3926 31.4386 98.8449 31.57 98.3209 31.6417C97.797 31.7372 97.3445 31.785 96.9634 31.785C95.6774 31.785 94.4628 31.57 93.3197 31.14C92.1765 30.71 91.1882 30.1008 90.3546 29.3124C89.5211 28.5241 88.8542 27.5804 88.3541 26.4815C87.8778 25.3587 87.6396 24.1165 87.6396 22.7548C87.6396 21.3931 87.8778 20.1627 88.3541 19.0638C88.8542 17.941 89.5211 16.9854 90.3546 16.1971C91.1882 15.4087 92.1765 14.7996 93.3197 14.3695C94.4628 13.9395 95.6774 13.7245 96.9634 13.7245C98.059 13.7245 99.1545 13.9395 100.25 14.3695C101.369 14.7757 102.346 15.4326 103.179 16.3404L100.214 19.3147Z"
              fill="white"
            />
            <path
              d="M109.303 16.5554H109.375C109.708 15.8387 110.291 15.1937 111.125 14.6204C111.982 14.0231 113.078 13.7245 114.411 13.7245C115.555 13.7245 116.519 13.9156 117.305 14.2979C118.115 14.6801 118.77 15.1818 119.27 15.8029C119.77 16.424 120.127 17.1407 120.342 17.953C120.556 18.7652 120.663 19.6133 120.663 20.4972V31.355H116.376V22.6473C116.376 22.1695 116.352 21.6439 116.305 21.0705C116.257 20.4733 116.126 19.9239 115.912 19.4222C115.721 18.8966 115.412 18.4666 114.983 18.1321C114.554 17.7738 113.971 17.5946 113.233 17.5946C112.518 17.5946 111.911 17.7141 111.411 17.953C110.934 18.1919 110.53 18.5144 110.196 18.9205C109.887 19.3266 109.66 19.7925 109.517 20.318C109.375 20.8436 109.303 21.3931 109.303 21.9664V31.355H105.016V4.26428H109.303V16.5554Z"
              fill="white"
            />
            <path
              d="M125.172 5.98432H142.141V11.1445H130.745V16.3046H141.283V21.4647H130.745V31.355H125.172V5.98432Z"
              fill="white"
            />
            <path
              d="M145.348 5.98432H150.92V31.355H145.348V5.98432Z"
              fill="white"
            />
            <path
              d="M167.778 12.1837C167.326 11.6103 166.707 11.1922 165.921 10.9294C165.159 10.6428 164.432 10.4994 163.742 10.4994C163.337 10.4994 162.92 10.5472 162.491 10.6428C162.062 10.7383 161.658 10.8936 161.277 11.1086C160.896 11.2997 160.586 11.5625 160.348 11.897C160.11 12.2314 159.991 12.6376 159.991 13.1153C159.991 13.8798 160.276 14.4651 160.848 14.8712C161.419 15.2773 162.134 15.6237 162.991 15.9104C163.872 16.1971 164.813 16.4838 165.813 16.7704C166.814 17.0571 167.743 17.4632 168.6 17.9888C169.481 18.5144 170.207 19.2191 170.779 20.103C171.351 20.9869 171.636 22.1695 171.636 23.6506C171.636 25.0601 171.374 26.2904 170.85 27.3415C170.327 28.3927 169.612 29.2647 168.707 29.9574C167.826 30.6502 166.79 31.1639 165.599 31.4983C164.432 31.8328 163.194 32 161.884 32C160.241 32 158.716 31.7492 157.311 31.2475C155.906 30.7458 154.596 29.9336 153.382 28.8108L157.347 24.439C157.919 25.2034 158.633 25.8007 159.49 26.2307C160.372 26.6368 161.277 26.8399 162.205 26.8399C162.658 26.8399 163.11 26.7921 163.563 26.6965C164.039 26.5771 164.456 26.4099 164.813 26.1948C165.194 25.9798 165.492 25.7051 165.706 25.3707C165.944 25.0362 166.064 24.642 166.064 24.1881C166.064 23.4237 165.766 22.8264 165.17 22.3964C164.599 21.9425 163.872 21.5603 162.991 21.2497C162.11 20.9392 161.158 20.6286 160.133 20.318C159.109 20.0075 158.157 19.5894 157.276 19.0638C156.394 18.5383 155.656 17.8455 155.061 16.9854C154.489 16.1254 154.203 14.9907 154.203 13.5812C154.203 12.2195 154.465 11.025 154.989 9.99776C155.537 8.97051 156.252 8.11049 157.133 7.41769C158.038 6.7249 159.074 6.21127 160.241 5.87682C161.408 5.51848 162.61 5.33931 163.849 5.33931C165.278 5.33931 166.659 5.54237 167.993 5.94849C169.326 6.35461 170.529 7.03546 171.601 7.99104L167.778 12.1837Z"
              fill="white"
            />
          </svg>
          <p>
            Our mission is to bring disruptive technologies to emerging markets
            through strategic partnerships, integrations and new business
            opportunities.
          </p>
        </div>
        <div className="mainfooter-company">
          <div className="mainfooter-company_1">Company</div>
          <div className="mainfooter-company_2">About Us</div>
          <div className="mainfooter-company_3">Services</div>
          <div className="mainfooter-company_4">Career</div>
          <div className="mainfooter-company_5">Contact Us</div>
        </div>
        <div className="mainfooter-legal">
          <div className="mainfooter-legal_1">Contact</div>
          <div className="mainfooter-legal_2">Head quarter: Suite 6, Floor 1, Hoang Thanh Tower 114 Mai Hac De, Hanoi, Vietnam</div>
          <div className="mainfooter-legal_3">Hanoi Office: 26 Ton Duc Thang, Cat Linh Ward, Dong Da District, Hanoi</div>
          <div className="mainfooter-legal_4">Ho Chi Minh Office: 202 Ly Chinh Thang, Ward 9, District 3, Ho Chi Minh</div>
          <div className="mainfooter-legal_5">info@techfis.com</div>
          <div className="mainfooter-legal_6">+84 24 3926 4083</div>
        </div>
        <div className="mainfooter-seperator"></div>
        <div className="mainfooter-copyright">
          Copyright © 2022 TechFIS. All Rights Reserved
        </div>
        <div className="mainfooter-ornaments_2">
          <div className="mainfooter-retangle_3"></div>
          <div className="mainfooter-retangle_4"></div>
        </div>
        <div className="mainfooter-ornaments_3"></div>
        <div className="mainfooter-social">
          <div className="mainfooter_face mainfooter_icon">
            <img src={facebook} />
          </div>
          <div className="mainfooter_twitter mainfooter_icon">
            <img src={twitter} />
          </div>
          <div className="mainfooter_insta mainfooter_icon">
            <img src={instagram} />
          </div>
          <div className="mainfooter_linkedin mainfooter_icon">
            <img src={linkedin} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
